<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-card>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_province">Filter by Province</label>
            <v-select
              id="filter_province"
              v-model="selected.filter_province"
              :options="list.provinces"
              label="province_name"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <strong>{{ province_name }}</strong>
              </template>
              <template #no-options="">
                No Available Provinces
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_municipality">Filter By Municipality</label>
            <v-select
              id="filter_municipality"
              v-model="selected.filter_municipality"
              :options="list.filter_municipalities"
              label="municipality_name"
              :disabled="state.busy"
              placeholder="-- Please Select Municipality --"
            >
              <template #option="{ municipality_name }">
                <span>{{ municipality_name }}</span>
              </template>
              <template #no-options="">
                No Available Municipalities
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Source</label>
            <b-select
              v-model="filter.filter_source"
              :options="filterSources"
              @change="onTableRefresh"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="success"
            @click="onShowImportDataForm"
          >
            Import Data
          </b-button>

          <b-button
            :disabled="tableSettings.totalRows < 1"
            class="mr-1"
            variant="primary"
            @click="state.selected ? onUnSelectAll() : onSelectAll()"
          >
            {{ state.selected ? 'Uncheck Current' : 'Select Current' }}
          </b-button>

          <b-button
            :disabled="selected.rawdata.length < 1"
            variant="secondary"
            @click="onShowAssignModal"
          >
            Assign Data
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(selected)="row">
          <div class="text-nowrap">
            <div class="form-group">
              <b-form-checkbox
                v-model="selected.rawdata"
                :value="row.item.id"
              />
            </div>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-import-data"
      scrollable
      no-close-on-backdrop
      title="Import Data"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div class="row">
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="province"
                vid="province"
                rules=""
              >
                <b-form-group>
                  <label for="province">Province</label>
                  <v-select
                    id="province"
                    v-model="selected2.province"
                    :options="list.provinces"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.provinces"
                    :loading="state.fetching.provinces"
                    label="province_name"
                  >
                    <template #option="{ province_name }">
                      <strong>{{ province_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Provinces
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="municipality"
                vid="municipality"
                rules=""
              >
                <b-form-group>
                  <label for="municipality">Municipality</label>
                  <v-select
                    id="municipality"
                    v-model="selected2.municipality"
                    :options="list.municipalities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.municipalities"
                    :loading="state.fetching.municipalities"
                    label="municipality_name"
                  >
                    <template #option="{ municipality_name }">
                      <strong>{{ municipality_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Municipalities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="barangay"
                vid="barangay"
                rules=""
              >
                <b-form-group>
                  <label for="barangay">Barangay</label>
                  <v-select
                    id="barangay"
                    v-model="selected2.barangay"
                    :options="list.barangays"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.barangays"
                    :loading="state.fetching.barangays"
                    label="barangay_name"
                  >
                    <template #option="{ barangay_name }">
                      <strong>{{ barangay_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Barangays
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="source"
                vid="source"
                rules="required"
              >
                <b-form-group>
                  <label for="sources">Source</label>
                  <v-select
                    id="sources"
                    v-model="selected2.source"
                    :options="list.sources"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="source_name"
                    :disabled="state.busy"
                  >
                    <template #option="{ source_name }">
                      <strong>{{ source_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Source
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="user"
                vid="user"
                rules=""
              >
                <b-form-group>
                  <label for="users">User</label>
                  <v-select
                    id="users"
                    v-model="selected2.user"
                    :options="list.users"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="full_name"
                    :disabled="state.busy"
                  >
                    <template #option="{ full_name }">
                      <strong>{{ full_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available User
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="agent"
                vid="agent"
              >
                <b-form-group>
                  <label for="agents">Agent</label>
                  <v-select
                    id="agents"
                    v-model="selected2.agent"
                    :options="list.agents"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="full_name"
                    :disabled="state.busy"
                  >
                    <template #option="{ full_name }">
                      <strong>{{ full_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Agents
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-group>
                  <label for="file">Attach File: </label>
                  <b-form-file
                    v-model="excelData.file"
                    :class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    accept=".xlsx"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || excelData.file === null || list.invalidRawData.length>0 || state.uploading)"
          @click="ok()"
        >
          Upload
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-assign-data"
      scrollable
      no-close-on-backdrop
      title="Assign Data"
      @ok="onConfirmAssign"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div class="col-12 mb-5">
            <ValidationProvider
              #default="{ errors }"
              name="agent"
              vid="agent"
              rules="required"
            >
              <b-form-group>
                <label for="agents">Agent</label>
                <v-select
                  id="agents"
                  v-model="selected.agent"
                  :options="list.agents"
                  :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                  label="full_name"
                  :disabled="state.busy"
                >
                  <template #option="{ full_name }">
                    <strong>{{ full_name }}</strong>
                  </template>
                  <template #no-options="">
                    No Available Agents
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-invalid-excel-data"
      title="Invalid Data"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-table
        ref="tableInvalidRawData"
        hover
        responsive
        :per-page="tableInvalidRawData.perPage"
        :current-page="tableInvalidRawData.currentPage"
        :items="list.invalidRawData"
        :fields="tableInvalidRawData.fields"
        :sort-by.sync="tableInvalidRawData.sortBy"
        :sort-desc.sync="tableInvalidRawData.sortDesc"
        :sort-direction="tableInvalidRawData.sortDirection"
        :filter="tableInvalidRawData.filter"
        :filter-included-fields="tableInvalidRawData.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ (data.index + 1) }}
        </template>

        <template #cell(line_error)="data">
          <div class="text-nowrap text-danger">
            {{ data.item.line_error }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableInvalidRawData.perPage"
              :options="tableInvalidRawData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableInvalidRawData.currentPage"
            :total-rows="tableInvalidRawData.totalRows"
            :per-page="tableInvalidRawData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedListService, SupervisorRawDataService, AxiosError } from '@/services'
import readXlsxFile from 'read-excel-file'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorRawData',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Raw Data'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        uploading: false,
        selected: false,
        fetching: {
          provinces: false,
          municipalities: false,
          barangays: false
        }
      },
      filter: {
        province: 'all',
        filter_source: 'All',
        filter_municipality: 'all'
      },
      selected: {
        agent: null,
        filter_province: null,
        filter_municipality: null,
        rawdata: []
      },
      selected2: {
        source: null,
        province: null,
        municipality: null,
        barangay: null,
        agent: null,
        user: null
      },
      list: {
        provinces: [],
        municipalities: [],
        filter_municipalites: [],
        barangays: [],
        sources: [],
        agents: [],
        users: [],
        invalidRawData: []
      },
      excelData: {
        file: null,
        province: null,
        municipality: null,
        barangay: null,
        source: null,
        agent: null,
        user: null,
        list: []
      },
      taggingData: {
        agent: null,
        selected: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'selected',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'mobile_number' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'status' },
          { key: 'remarks' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableInvalidRawData: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'mobile_number' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'remarks' },
          { key: 'line_number' },
          { key: 'line_error' }
        ]
      }
    }
  },
  computed: {
    filterSources () {
      return [{ value: 'All', text: 'All' }].concat(this.list.sources.map(item => {
        return {
          value: item.id,
          text: item.source_name
        }
      }))
    }
  },

  watch: {
    'selected.rawdata' (value) {
      this.taggingData.selected = value
      if (value.length > 0) {
        this.state.selected = true
      } else {
        this.state.selected = false
      }
    },

    'selected.agent' (value) {
      this.taggingData.agent = value?.id || null
    },

    'selected.filter_province' (value) {
      this.filter.province = value?.id || null
      this.getFilterMunicipalities(value?.id || null)
      this.onFilterChanged()
    },

    'selected2.province' (value) {
      this.excelData.province = value?.id || null
      this.getMunicipalities(this.excelData.province)
    },

    'selected2.municipality' (value) {
      this.excelData.municipality = value?.id || null
      this.getBarangays(this.excelData.municipality)
    },

    'selected.filter_municipality' (value) {
      this.filter.filter_municipality = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected2.barangay' (value) {
      this.excelData.barangay = value?.id || null
    },

    'selected2.agent' (value) {
      this.excelData.agent = value?.id || null
    },

    'selected2.user' (value) {
      this.excelData.user = value?.id || null
    },

    'selected2.source' (value) {
      this.excelData.source = value?.id || null
    },

    'excelData.file' (value) {
      if (value) {
        this.onParseExcelFile(value)
      }
    },

    'list.invalidRawData' (value) {
      this.tableInvalidRawData.totalRows = value?.length || 0
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getProvinces()
    this.getAgents()
    this.getUsers()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorRawDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_province: this.filter.province,
            filter_municipality: this.filter.filter_municipality,
            filter_source: this.filter.filter_source
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected2.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected2.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getFilterMunicipalities (province) {
      this.selected.filter_municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.filter_municipalities = data
        })
      } else {
        this.list.filter_municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected2.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected2.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getAgents () {
      await SharedListService.getAgents().then(({ data }) => {
        this.list.agents = data
      })
    },

    async getUsers () {
      await SharedListService.getUsers().then(({ data }) => {
        this.list.users = data
      })
    },

    async getSources () {
      await SharedListService.getSources().then(({ data }) => {
        this.list.sources = data
      })
    },

    onShowImportDataForm () {
      this.selected2.source = null
      this.selected2.province = null
      this.selected2.municipality = null
      this.selected2.barangay = null
      this.selected2.agent = null
      this.selected2.user = null
      this.excelData.file = null
      this.list.invalidRawData = []
      this.$bvModal.show('modal-import-data')
    },

    async onParseExcelFile (data) {
      await readXlsxFile(data).then(excelRow => {
        this.excelData.data = []

        if (excelRow.length <= 1) {
          return 'No entries found'
        }

        if (
          excelRow[0][0] !== 'mobile_number' ||
            excelRow[0][1] !== 'first_name' ||
            excelRow[0][2] !== 'last_name' ||
            excelRow[0][3] !== 'remarks'
        ) {
          return 'Column Titles must be (mobile_number, first_name, last_name, remarks)'
        }

        if (excelRow.length >= 501) {
          return 'Entries must not exceed beyond 500'
        }

        // reset list
        this.list.invalidRawData = []
        this.excelData.list = []

        for (let i = 1; i <= excelRow.length - 1; i++) {
          let isOk = true
          let error = ''

          if (
            excelRow[i][0] === null ||
            excelRow[i][1] === null ||
            excelRow[i][2] === null ||
            excelRow[i][3] === null
          ) {
            isOk = false
            error = 'row value cannot be empty.'
          }

          if (isOk) {
            if (String(excelRow[i][0]).length !== 11) {
              isOk = false
              error = 'mobile_number should be 11 characters.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 50) {
              isOk = false
              error = 'first_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][2]).length > 50) {
              isOk = false
              error = 'last_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][3]).length > 200) {
              isOk = false
              error = 'remarks should contain maximum of 200 characters only.'
            }
          }

          if (isOk) {
            this.excelData.list.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              remarks: excelRow[i][3],
              line_number: i + 1,
              line_error: ''
            })
          } else {
            this.list.invalidRawData.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              remarks: excelRow[i][3],
              line_number: i + 1,
              line_error: error
            })
          }
        }

        if (this.list.invalidRawData.length > 0) {
          this.$bvModal.show('modal-invalid-excel-data')
        }

        return null
      })
        .then(error => {
          if (error) {
            this.$refs.form.setErrors({
              file: [error]
            })
          }
        })
        .catch(() => {
          this.$refs.form.setErrors({
            file: ['There was a problem reading your uploaded excel file.']
          })
        })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to upload raw data?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onUploadFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onUploadFormPostSubmit () {
      return new Promise(resolve => {
        this.state.uploading = true
        SupervisorRawDataService.post(this.excelData).then(({ data }) => {
          this.state.uploading = false
          this.$bvModal.hide('modal-import-data')
          this.list.invalidRawData = data.invalid_raw_data

          if (data.total_uploaded === data.total_valid) {
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }

          if (data.total_uploaded === data.total_invalid) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! All the raw data you uploaded in invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }

          if (parseInt(data.total_valid) > 0 && parseInt(data.total_invalid) > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! Some of the raw data you uploaded is invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }
        })
          .catch(error => {
            this.state.uploading = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onShowAssignModal () {
      this.selected.agent = null
      this.$bvModal.show('modal-assign-data')
    },

    async onConfirmAssign (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Are you sure you want to assign the raw data in this agent?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorRawDataService.put(this.taggingData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-assign-data')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.selected.rawdata = []
              this.state.selected = false
              this.$refs.table.refresh()
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onSelectAll () {
      this.state.selected = true
      this.selected.rawdata = this.$refs.table.localItems?.map(item => {
        return item.id
      })
    },

    onUnSelectAll () {
      this.state.selected = false
      this.selected.rawdata = []
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
